import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CardBody } from "reactstrap";
import { getAllCheckIn, settleLoyaltyAmount } from "../../actions/axios-actions";
import Loader from "../../CommonElements/Loader";
import { dateFormat } from "./index";
import { Badges, Btn, H6 } from "../../AbstractElements";
import moment from "moment";
import DailyCheckInModal from "./DailyCheckInModal";
import AppSetupStatusModal from "./AppSetupStatusModal";
import { CloseIcon, EmptyRoundIcon, MinusCircleIcon, TickIcon } from "./DailyCheckinSvgIcons";
import { toast } from "react-toastify";
import { SettleInfoModal } from "./SettleInfoModal";
import { LoyaltySettleConfirmation } from "./LoyaltySettleConfirmation";
import { useNavigate } from "react-router-dom";


const DailyCheckIn = (props) => {
  let {
    checkInData,
    setCheckInData,
    loading,
    setLoading,
    myTotalRows,
    setMyTotalRows,
    myPerPage,
    setMyPerPage,
    myCurrentPage,
    setMyCurrentPage,
    searchText,
    setSearchText,
    startDate,
    endDate,
    dailyCheckInFltr, setDailyCheckInFltr
  } = props;

  const [open, setOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [appSetupInfo, setAppSetupInfo] = useState([]);
  const [settleModalOpen, setSettleModalOpen] = useState(false);
  const [settleModalInfo, setSettleModalInfo] = useState("");
  const [email, setEmail] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();
  const toggle = (row) => {
    setOpen(!open);
    setEmail(row?.shashiId || row?.pmsEmailId || row?.primaryEmailId || "");
    if(row?.shashiId) setIsSignUp(true);
    else setIsSignUp(false);
  }

  const openAppSetupModal = (row) => {
    setAppModalOpen(!appModalOpen);
    setAppSetupInfo([
      {
        text: "Email Verification : ",
        status: row?.isEmailVerified ? "Done" : "Pending"
      },
      {
        text: "Shashi Cash Enrollment : ",
        status: row?.shashiCashEnrollment ? "Done" : "Pending"
      },
      {
        text: "User Profile Setup : ",
        status: row?.isUserProfileSetup ? "Done" : "Pending"
      },
      {
        text: "Express Checkin Setup : ",
        status: row?.expressCheckInSetup ? "Done" : "Pending"
      },
      {
        text: "Add Card : ",
        status: row?.isCardAdded ? "Done" : "Pending"
      }
    ])
  }

  const openSettleInfoModal = () => {
    setSettleModalOpen(!settleModalOpen);
  }

  const openConfirmModal = () => {
    setConfirmOpen(!confirmOpen);
  }

  useEffect(() => {
    setSearchText("");
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      myCurrentPage,
      myPerPage,
      dailyCheckInFltr
    );
  }, []);

  const handlePageChange = (page) => {
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      (myCurrentPage = page),
      myPerPage,
      dailyCheckInFltr
    );
    setMyCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      (myCurrentPage = page),
      dailyCheckInFltr
    );
    setMyPerPage(newPerPage);
  };

  const settlementCheck = (rowData) => {
    if(rowData.shashiCashEnrollment && rowData.isEmailVerified) {
      setRowInfo(rowData);
      setConfirmOpen(true);
    } else {
      if(!rowData.shashiCashEnrollment && !rowData.isEmailVerified) {
        setSettleModalInfo("Shashi cash enrollment and Email verification needs to be completed for settling loyalty.");
      } else if(!rowData.shashiCashEnrollment) {
        setSettleModalInfo("Shashi cash enrollment needs to be completed for settling loyalty");
      } else if(!rowData.isEmailVerified) {
        setSettleModalInfo("Email verification needs to be completed for settling loyalty");
      }

      setSettleModalOpen(true);
    }
  }

  const settleLoyalty = async () => {
    let status;
    let statusMessage;
    const token = localStorage.getItem("token");
    
    await settleLoyaltyAmount(setLoading, rowInfo.reservationId, token, (err, res) => {
      if(err) statusMessage = err.response?.data?.message;
      else {
        status = res.data?.status;
        statusMessage = res.data?.message;
      }
    });

    if(status) {
      toast.success(
        statusMessage ?? "Loyalty settled successfully",
        { position: toast.POSITION.TOP_RIGHT }
      );

      getAllCheckIn(
        setCheckInData,
        setLoading,
        dateFormat(startDate),
        dateFormat(endDate),
        searchText,
        setMyTotalRows,
        myCurrentPage,
        myPerPage
      );
    } else {
      toast.error(
        statusMessage ?? "Loyalty settlement failed",
        { position: toast.POSITION.TOP_RIGHT }
      );
    }

    setRowInfo(null);
  }

  const AllDailyCheckInColumns = [
    {
      name: <H6>Guest Name</H6>,
      selector: (row) => {
        let firstWord="-",secondWord="-";
       
        let words = row.firstName ? row.firstName.toLowerCase().split(" ") : "-";
        let capitalizedWords1;
        if(words != "-"){

           capitalizedWords1 = words.map((word, index) => {
              if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
              } else {
                  return word;
              }
          });
          firstWord =  capitalizedWords1.join(" ");
        }
         words = row.lastName ? row.lastName.toLowerCase().split(" ") : "-";
         let capitalizedWords2;
         if(words != "-"){
          capitalizedWords2 =  words.map((word, index) => {
              if (index === 0) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
              } else {
                  return word;
              }
          });
          secondWord =  capitalizedWords2.join(" ");
         }
        
        console.log(firstWord + "" + secondWord )
        return firstWord + " " + secondWord;
    },
      sortable: true,
      // center: true,
      minWidth: "160px",
      wrap: true
    },
    {
      name: <H6>Email ID</H6>,
      selector: (row) => row.pmsEmailId ? row.pmsEmailId : "-",
      sortable: true,
      // center: true,
      minWidth: "250px",
      wrap: true
    },
    {
      name: <H6>Reservation Eligible For Shashi Cash</H6>,  
      selector: (row) => row.isEligibleForShashiCash,
      cell: (row) =>
        row.isEligibleForShashiCash ? <TickIcon /> : <CloseIcon />,
      sortable: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: <H6>Shashi Cash $(%)</H6>,
      selector: (row) => {
        let value = parseFloat(row.shashiCash).toFixed(2);
        return (
          <span
            style={{
              color: row.isEligibleForShashiCash ? "#000000" : "#FF170A",
            }}
          >
            {/* ${row.shashiCash ? value : "0.00"} */}$
            {row.shashiCash
              ? `${value} (${row?.loyaltyPercentageRedeem}%)`
              : "0.00 - 0%"}
          </span>
        );
      },
      sortable: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: <H6>Shashi Cash Enrollment Status</H6>,
      selector: (row) => row.shashiCashEnrollment,
      cell: (row) =>
        row.shashiCashEnrollment ? (
          <TickIcon />
        ) : (
          <div onClick={() => toggle(row)} style={{ cursor: "pointer" }}>
            <EmptyRoundIcon />
          </div>
        ),
      sortable: true,
      center: true,
      minWidth: "143px",
    },
    {
      name: <H6>App Setup Status</H6>,
      selector: (row) => row.appSetupStatus,
      cell: (row) =>
        row.appSetupStatus === 1 ? (
          <TickIcon />
        ) : row.appSetupStatus === 0 ? (
          <div
            onClick={() => openAppSetupModal(row)}
            style={{ cursor: "pointer" }}
          >
            <MinusCircleIcon />
          </div>
        ) : (
          <EmptyRoundIcon />
        ),

      sortable: true,
      center: true,
      minWidth: "150px",
    },
    {
      name: <H6>Shashi ID</H6>,
      selector: (row) => row.shashiId && (!row.shashiId.includes("+deleted")) ? row.shashiId : "-",
      sortable: true,
      // center: true,
      minWidth: "250px",
      wrap: true
    },
    {
      name: <H6>Reservation ID</H6>,
      selector: (row) => row.reservationId,
      sortable: true,
      center: true,
      minWidth: "170px",
    },
    {
      name: <H6>Arrival Date</H6>,
      selector: (row) => moment.unix(row.arrivalDate).utc().format("MMM DD, YYYY"),
      sortable: true,
      center: true,
      minWidth: "160px",
    },
    {
      name: <H6>Departure Date</H6>,
      selector: (row) => moment.unix(row.departureDate).utc().format("MMM DD, YYYY"),
      sortable: true,
      center: true,
      minWidth: "160px",
    },
    {
      name: <H6>Check-In Time</H6>,
      selector: (row) => row.checkInTime,
      sortable: true,
      center: true,
      minWidth: "160px",
    },
    {
      name: <H6>Check-Out Time</H6>,
      selector: (row) => row.checkOutTime,
      sortable: true,
      center: true,
      minWidth: "160px",
    },
    {
      name: <H6>Status</H6>,
      selector: (row) =>
        row.inforStatus == "In-house" ? (
          <div className="text-center" style={{ fontSize: "15px" }}>
            <Badges
              attrBadge={{ color: "success", style: { borderRadius: "0px" } }}
            >
              In-house
            </Badges>
          </div>
        ) : row.inforStatus == "Reserved" ? (
          <div className="text-end" style={{ fontSize: "15px" }}>
            <Badges
              attrBadge={{ color: "warning", style: { borderRadius: "0px" } }}
            >
              Reserved
            </Badges>
          </div>
        ) : row.inforStatus == "No-Show" ? (
          <div className="text-end" style={{ fontSize: "15px" }}>
            <Badges attrBadge={{ color: "info", style: { borderRadius: "0px" } }}>
              No-Show
            </Badges>
          </div>
        ) : row.inforStatus == "Cancelled" ? (
          <div className="text-end" style={{ fontSize: "15px" }}>
            <Badges
              attrBadge={{ color: "danger", style: { borderRadius: "0px" } }}
            >
              Cancelled
            </Badges>
          </div>
        ) : row.inforStatus == "Checked-out" ? (
          <div className="text-end" style={{ fontSize: "15px" }}>
            <Badges
              attrBadge={{ color: "danger", style: { borderRadius: "0px" } }}
            >
              Checked-out
            </Badges>
          </div>
        ) : (
          ""
        ),
      sortable: true,
      center: true,
      minWidth: "110px",
    },
    {
      name: <H6>Room No</H6>,
      selector: (row) => row.roomNo,
      sortable: true,
      center: true,
      minWidth: "120px",
    },
    {
      name: <H6>Loyalty Settlement</H6>,
      cell: (row) => (
        <div style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
          {
            row.loyaltyRedeemStatus == "redeemed" ? (
              <TickIcon />
            ) : row.loyaltyRedeemStatus == "cross" ? (
              <CloseIcon />
            ) : row.loyaltyRedeemStatus == "disabled" ? (
              <Btn
                attrBtn={{
                  color: "dark",
                  outline: true,
                  disabled: true,
                  className: "rounded-0 disabled-settle",
                  style: {
                    borderColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                Settle Loyalty
              </Btn>
            ) : row.loyaltyRedeemStatus == "button" ? (
              <Btn
                attrBtn={{
                  className: "rounded-0 bg-black text-white",
                  onClick: () => settlementCheck(row)
                }}
              >
                Settle Loyalty
              </Btn>
            ) : (
              ""
            )
          }
        </div>
      ),
      center: true,
      minWidth: "200px"
    }
  ];

  const showPreferences = (rowData) => {
    console.log(rowData,"rowData");
    return navigate(
      `/profile-360?e=${btoa(rowData.emailId || "")}&i=${btoa(
        rowData.reservationId || ""
      )}&linkedInUrl=${btoa(rowData?.linkedInUrl || ""
      )}&lN_slt_skip=${btoa(rowData.isLinkedInSelectionSkip || ""
      )}`
    );
    if(rowData.shashiId) navigate(`/mobile-app-reservations/${rowData?.id}`, { state: { fromPage: "checkinTable" } });
  }

  return (
    <Fragment>
      {
        open
          ? <DailyCheckInModal
              email={email}
              setEmail={setEmail}
              isOpen={open}
              title={"Shashi Cash Enrollment"}
              toggle={toggle}
              setOpen={setOpen}
              isSignUp={isSignUp}
              setIsSignUp={setIsSignUp}
              setCheckInData={setCheckInData}
              setLoading={setLoading}
              dateFormat={dateFormat}
              searchText={searchText}
              setMyTotalRows={setMyTotalRows}
              myCurrentPage={myCurrentPage}
              myPerPage={myPerPage}
              startDate={startDate}
              endDate={endDate}
            />
          : null
      }
      {
        appModalOpen
          ? <AppSetupStatusModal
            isOpen={appModalOpen}
            setOpen={setAppModalOpen}
            title={"App Setup Status"}
            toggle={openAppSetupModal}
            data={appSetupInfo}
          />
          : null
      }
      {
        settleModalOpen
          ? <SettleInfoModal
            isOpen={settleModalOpen}
            toggle={openSettleInfoModal}
            data={settleModalInfo}
          />
          : null
      }
      {
        confirmOpen
          ? <LoyaltySettleConfirmation
              isOpen={confirmOpen}
              toggle={openConfirmModal}
              settleLoyalty={settleLoyalty}
            />
          : null
      }
      <CardBody>
        <div className="order-history table-responsive">
          <DataTable
            columns={AllDailyCheckInColumns}
            data={checkInData}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={myTotalRows}
            paginationDefaultPage={myCurrentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            searching={true}
            defaultSortField={"Check-in date"}
            defaultSortAsc={true}
            onRowClicked={showPreferences}
            paginationRowsPerPageOptions={[10,15,20,25,30, 50, 100]}
           
          />
        </div>
      </CardBody>
      <style jsx global>
        {`
            .kIaWtg {
            padding-top: 7px;
            padding-bottom: 7px;
            }
            .epNTxY {
            padding-top: 7px;
            padding-bottom: 7px;
            padding-left: 4px;
            }
            ..dTBAzQ {
            padding-left: 5px;
            }
            .order-history .rdt_Table .rdt_TableHead h6, .order-history .rdt_Table .rdt_TableHead .h6{
            text-align: center;  
            }

            .disabled-settle {
              background-color: #f2f2f2 !important;
            }
        `}
      </style>
    </Fragment>
  );
};

export default DailyCheckIn;